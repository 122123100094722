<template>
  <el-container class="home-container">
    <!--头部-->
    <el-header>
      <div style="display:flex">
        <div class="toggle-button" @click="toggleCollapse">
          <i v-if="isCollapse===false" class="el-icon-s-fold" style="fontSize: 20px"></i>
          <i v-if="isCollapse===true" class="el-icon-s-unfold" style="fontSize: 20px"></i>
        </div>
        <div class="header-logo">
          <!-- <img src="../assets/logo.png"> -->
          {{ enterpriseName }}
        </div>
      </div>
      <div style="display: flex">
        <div class="right_bell"><i class="el-icon-bell"></i></div>
        <div class="nameBox">
          <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              <img class="picture" src="../assets/images/0306072840247.jpeg" alt=""> <div style="marginLeft: 10px">{{ userName }}</div><i style="marginTop: 10px" class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="2">修改密码</el-dropdown-item>
              <el-dropdown-item  command="1">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>

    </el-header>

    <el-dialog
              title="修改密码"
              :visible.sync="passwordVisible"
              :append-to-body='true'
              :modal-append-to-body='true'
              width="50%"
              :before-close="handleClose">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
          
          <el-form-item  label="原密码" prop="oldPassword" style="width: 600px;">
            <el-input v-model="ruleForm.oldPassword" placeholder="请输入原密码" ></el-input>
          </el-form-item>
          <el-form-item  label="新密码" prop="newPassword" style="width: 600px;">
            <el-input v-model="ruleForm.newPassword" placeholder="请输入新密码" ></el-input>
          </el-form-item>
          <el-form-item  label="确认密码" prop="password" style="width: 600px;">
            <el-input v-model="ruleForm.password" placeholder="请再次输入新密码" ></el-input>
          </el-form-item>
          
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="resetForm('ruleForm')">取 消</el-button>
          <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
        </span>
      </el-dialog>

    <!--主体-->
    <el-container style="height: 100%">
      <!-- 左侧菜单 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <el-menu
                background-color="#FFFFFF"
                text-color="#0A0B1A"
                active-text-color="#409FFF"
                :unique-opened="true"
                :collapse="isCollapse"
                :collapse-transition="false"
                :router="true"
                :default-active="activePath">
          <template v-for="(menu,menuIndex) in menuList">
          <!--一级菜单-->
          <el-submenu :index="menu.id + ''" v-if="menu.children" :key="menu.id">
            <!--一级菜单的模板区域-->
            <template slot="title">
              <i :class="icons[menuIndex]"></i>
              <span>{{menu.authName}}</span>
            </template>

            <!--二级菜单-->
            <el-menu-item :index="subMenu.path" v-for="subMenu in menu.children" :key="subMenu.id"
                          @click="saveNavState(subMenu.path)">
              <template slot="title">
                <!-- <i class="el-icon-menu"></i> -->
                <span>{{subMenu.authName}}</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!-- 没有二级菜单的情况 -->
          <el-menu-item v-else :index="menu.path" @click="saveNavState(menu.path)" :default-active="activePath">
            <i :class="icons[menuIndex]"></i>
            <span slot="title">{{menu.authName}}</span>
          </el-menu-item> 
          </template>
        </el-menu>
      </el-aside>

      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
    <el-footer style="height: 40px" class="footerBox">由<span style="color: #616FFE"><a href="http://www.sysongy.com/#/front/homePage" target="_blank">陕西司集能源</a></span>提供技术支持 Powered by SYSONGY</el-footer>
  </el-container>
</template>

<script>
import {getMenuListRequest,getEnterpriseIdRequest,editPassword} from "network/home";
import { getQueryStyleRequest } from 'network/rights'


  export default {
    name: "Home",
    data() {
      let validateNewPassword = (rule, value, callback) => {
          if (value === this.ruleForm.oldPassword) {
              callback(new Error('新密码不能与原密码相同!'))
          } else {
              callback()
          }
      }
      let validateNewPassword2 = (rule, value, callback) => {
          if (value !== this.ruleForm.newPassword) {
              callback(new Error('与新密码不一致!'))
          } else {
              callback()
          }
      }
      let validPassword=(rule,value,callback)=>{
        let reg= /^[0-9A-Za-z]{6,18}$/
        if(!reg.test(value)){
          callback(new Error('密码必须是由6-18位数字、字母或字母+数字组合'))
        }else {
          callback()
        }
    }
      return {
        enterpriseName: '',
        userName: '',
        menuList: [],
        icons: ['iconfont icon-users', 'iconfont icon-tijikongjian', 'iconfont icon-shangpin', 'iconfont icon-danju', 'iconfont icon-baobiao', 'iconfont icon-3702mima', 'iconfont icon-morentouxiang', 'iconfont icon-users','iconfont icon-tijikongjian', 'iconfont icon-shangpin', 'iconfont icon-danju',],
        isCollapse: false,
        activePath: '',// 被激活的链接地址
        imageUrl: '',
        passwordVisible: false,
        ruleForm: {
          oldPassword:'',
          newPassword: '',
          password: ''
        },
        rules: {
          oldPassword: [
            { required: true,message: '请输入原密码',trigger: 'blur' },
            { validator: validPassword, trigger: 'blur' },
          ],
          newPassword: [
            { required: true,message: '请输入新密码',trigger: 'blur' },
            { validator: validateNewPassword, trigger: 'blur' },
            { validator: validPassword, trigger: 'blur' },
            
          ],
          password: [
            { required: true,message: '请再次输入新密码', trigger: 'blur' },
            { validator: validateNewPassword2, trigger: 'blur' },
            { validator: validPassword, trigger: 'blur' },
          ]
          
        },
      }
    },
    created() {
      this.enterpriseName = localStorage.getItem('enterpriseName')
      // this.activePath = localStorage.getItem('activePath')
      this.getMenuList();
      this.getEnterpriseId();
      this.getTheme()
      // this.getActivePath()
    },
    methods: {
      handleClose(){
        this.passwordVisible=false
        this.$refs.ruleForm.resetFields();
      },
      resetForm() {
        this.passwordVisible=false
        this.$refs.ruleForm.resetFields();
      },
      submitForm() {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            editPassword({
              userName: localStorage.getItem('userName'),
              oldPassword: this.ruleForm.oldPassword,
              newPassword: this.ruleForm.newPassword
            }).then(res=>{
              let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage(result.message, 'error');
                }
                this.passwordVisible=false
                this.$refs.ruleForm.resetFields();
                localStorage.clear();
                this.$router.replace('/login');
            })
          }else {
            return false;
          }
        })
      },
      handleCommand(command) {
        if(command == 1){
          this.loginOut()
        }if(command == 2){
          this.passwordVisible = true
        }
      
      },
      // getActivePath(){
      //   console.log(localStorage.getItem('activePath'));
      //   if(localStorage.getItem('type')==1){
      //     if(localStorage.getItem('activePath')==null){
      //       this.activePath = '/pages/biz/order/internal'
      //       this.$router.push({path: '/pages/biz/order/internal',replace:true})
      //     }else{
      //       this.activePath = localStorage.getItem('activePath')
      //     }
      //   }else{
      //     if(localStorage.getItem('activePath') == null){
      //       this.activePath = '/pages/biz/gas_factory/order'
      //       this.$router.push({path: '/pages/biz/gas_factory/order',replace:true})
      //     }else{
      //       this.activePath = localStorage.getItem('activePath')
      //     }
      //   }
      // },
      loginOut() {
        localStorage.clear();
        this.$router.replace('/login');
      },
      getMenuList() {
        getMenuListRequest().then(res => {
          let result = res.data;
          if (result.code !== 200) {
            this.$message({
              message: result.message,
              type: 'error'
            })
          }
          // this.menuList = result.data.list;
          this.menuList = result.data.list;

          this.activePath = localStorage.getItem('activePath')==null?result.data.index:localStorage.getItem('activePath')
          this.$router.push({path: localStorage.getItem('activePath')==null?result.data.index:localStorage.getItem('activePath'),replace:true}).catch(() => true)
        })
      },
      getTheme(){
        getQueryStyleRequest({}).then(res=>{
          
          this.imageUrl = this.api.imageUrl + '/trade'+res.data.data.logoPath
          let newColor = !res.data.data.color||res.data.data.color==''?'#616FFE': res.data.data.color
          document.documentElement.style.setProperty("--theme_bg_color", newColor);
        })
      },
      getEnterpriseId(){
        getEnterpriseIdRequest().then(res => {
          let result = res.data;
          if(result.code !== 200){
            this.$message({
              message: result.message,
              type: 'error'
            })
          }
          localStorage.setItem('enterpriseId',result.data.enterpriseId)
          this.userName = result.data.userName
        })
      },
      // 点击按钮切换左侧菜单的折叠与展开
      toggleCollapse() {
        this.isCollapse = !this.isCollapse;
      },
      // 保存当前激活的路径
      saveNavState(activePath) {
        // 保存到sessionStorage中是为了在刷新的时候能够取出这个路径并将对应的菜单高亮
        localStorage.setItem('activePath', activePath);
        
        this.activePath = activePath;
      },
      popstate(e){
        let newPath = e.currentTarget.location.pathname
        console.log(newPath);
        if(newPath == '/home'){
          this.$router.push({path: '/login',replace:true}).catch(() => true)
          localStorage.clear();
        }else{
          localStorage.setItem('activePath',newPath)
          this.activePath = newPath
        } 
      }
    },

    mounted() { // 创建实例后执行
      // 浏览器控制按钮前进后退触发函数
      window.addEventListener('popstate', this.popstate, false);
    },
    // 销毁组件
    destroyed () {
      // 避免堆栈溢出，多次创建、多次触发
      window.removeEventListener('popstate', this.popstate, false);
    },
  }
</script>

<style scoped>
  .home-container {
    background-color: rgb(233, 237, 241);
    height: 100%;
  }

  .el-header {
    background-color: #FFFFFF;
    color: #0A0B1A;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
  }

  .header-logo {
    display: flex;
    align-items: center;
  }

  .header-logo img {
    height: 50px;
  }

  /* 左侧菜单 */
  .el-aside {
    background-color: #fff;
    color: white;
    user-select: none;
    margin-top: 20px;
    margin-bottom: 20px;
    transition: width 0.3s;
  }

  .iconfont {
    margin-right: 10px;
  }

  .el-menu {
    border-right: none;
  }

  .toggle-button {
    display: flex;
    justify-content: baseline;
    text-align: center;
    margin-top: 5px;
    margin-right: 36px;
  }

  /* 右侧内容区 */
  .el-main {
    background-color: rgb(233, 237, 241);
  }
  .footerBox{
    background-color: #fff;
    width: 100%;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    font-weight: 400;
    color: #3d3d3d;
  }
  .picture{
    width: 32px;
    height: 32px;
    border-radius: 20px;
    /* margin-top: 5px; */
  }
  .nameBox{
    display: flex;
    height: 32px;
    font-size: 14px;
    font-weight: 400;
    color: #54555f;
    line-height: 32px;
  }
  .el-dropdown-link{
    display: flex;
  }
  .right_bell{
    width: 32px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    font-size: 16px;
    margin-right: 20px;
  }

  .el-menu-item:hover{
    background-color: #EFF0FE !important;
    color: var(--theme_bg_color) !important;
  }

  .el-menu-item.is-active{
  background-color: var(--theme_bg_color) !important;
  color: #fff !important;
  }

</style>
